import { createContext, useContext, useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { makeStyles } from '@mui/styles';

import { divIcon } from 'leaflet';

import LocationOn from '@mui/icons-material/LocationOn';

import { useImportCSS } from './resourceUtil';

const TNBoundaryContext = createContext();
const MarkerContext = createContext();

export function useTNBoundary() {
    return useContext(TNBoundaryContext);
}
export function useMarkerPins() {
    return useContext(MarkerContext);
}

const useStyles = makeStyles((theme) => ({
    pin: {
        margin: '-1rem !important',
        fontSize: '2.5rem !important',
    },

    pin_main: {
        color: theme.palette.primary.dark,
    },
    pin_secondary: {
        color: theme.palette.secondary.dark,
    },
    pin_tertiary: {
        color: theme.palette.tertiary.dark,
    },
    pin_error: {
        color: theme.palette.error.dark,
    },

    pin_red: {
        color: 'red',
    },
    pin_gray: {
        color: 'gray',
    },
    pin_blue: {
        color: 'blue',
    },
    pin_orange: {
        color: 'orange',
    },
    pin_green: {
        color: 'green',
    },
    pin_violet: {
        color: 'violet',
    },
    pin_fuschsia: {
        color: 'fuschsia',
    },
    pin_olive: {
        color: 'olive',
    },
    pin_maroon: {
        color: 'maroon',
    },
    pin_aqua: {
        color: 'aqua',
    },
    pin_lime: {
        color: 'lime',
    },
    pin_navy: {
        color: 'navy',
    },
    pin_teal: {
        color: 'teal',
    },
    pin_silver: {
        color: 'silver',
    },
}));

export default function MapUtilsProvider({ children }) {
    useImportCSS(
        'https://unpkg.com/leaflet@1.8.0/dist/leaflet.css',
        'sha512-hoalWLoI8r4UszCkZ5kL8vayOGVae1oxXe/2A4AO6J9+580uKHDO3JdHb7NzwwzK5xr/Fs0W40kiNHxM9vyTtQ=='
    );

    const myClasses = useStyles();

    const [TNBoundary, setTNBoundary] = useState(null);

    useEffect(() => {
        fetch('/rest/tnboundary')
            .then((resp) => resp.json())
            .then((json) => {
                setTNBoundary(json);
            });
    }, []);

    const pin_main = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_main} />) });
    const pin_secondary = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_secondary} />) });
    const pin_tertiary = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_tertiary} />) });
    const pin_error = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_error} />) });

    const pin_red = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_red} />) });
    const pin_gray = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_gray} />) });
    const pin_blue = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_blue} />) });
    const pin_orange = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_orange} />) });
    const pin_green = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_green} />) });
    const pin_violet = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_violet} />) });
    const pin_fuschsia = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_fuschsia} />) });
    const pin_olive = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_olive} />) });
    const pin_maroon = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_maroon} />) });
    const pin_aqua = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_aqua} />) });
    const pin_lime = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_lime} />) });
    const pin_navy = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_navy} />) });
    const pin_teal = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_teal} />) });
    const pin_silver = divIcon({ html: renderToStaticMarkup(<LocationOn className={myClasses.pin + ' ' + myClasses.pin_silver} />) });

    const pins = {
        main: pin_main,
        secondary: pin_secondary,
        tertiary: pin_tertiary,
        error: pin_error,

        series: [
            pin_red,
            pin_gray,
            pin_blue,
            pin_orange,
            pin_green,
            pin_violet,
            pin_fuschsia,
            pin_olive,
            pin_maroon,
            pin_aqua,
            pin_lime,
            pin_navy,
            pin_teal,
            pin_silver,
        ],
    };

    return !!TNBoundary ? (
        <TNBoundaryContext.Provider value={TNBoundary}>
            <MarkerContext.Provider value={pins}>{children}</MarkerContext.Provider>
        </TNBoundaryContext.Provider>
    ) : null;
}
