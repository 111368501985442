import Grid from '@mui/material/Grid';

import utils from '../components/utils';

import { useDistrictCount, useSchoolCount } from '../DataProvider';

export default function StateHeader({ state, classes }) {
    const districtCount = useDistrictCount();
    const schoolCount = useSchoolCount();

    return !!state ? (
        <Grid container spacing={2} className={classes.header} style={{ backgroundImage: "url('/images/home-header-index.png')" }}>
            <Grid item xs={12} md={7} style={{ fontSize: '.9rem' }}>
                <h1 style={{ textTransform: 'uppercase', textAlign: 'center' }}>{state.name}</h1>

                <Grid item container>
                    <Grid item xs={6}>
                        Districts: <b>{districtCount - 1}</b>
                    </Grid>

                    <Grid item xs={6}>
                        Schools: <b>{schoolCount}</b>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} className={classes.headerRight}>
                <p>
                    <b>WEBSITE:</b>
                    <br />
                    <a href={utils.ensureProtocol(state.website)} target='_blank' style={{ color: 'white' }}>
                        {utils.formatURL(state.website)}
                    </a>
                </p>

                <p>
                    <b>ADDRESS:</b>
                    <br />
                    {state.address}
                </p>

                <p>
                    <b>PHONE:</b>
                    <br />
                    {utils.formatPhone(state.phone)}
                </p>
            </Grid>
        </Grid>
    ) : null;
}
