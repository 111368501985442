import { useState } from 'react';

import { makeStyles } from '@mui/styles';

import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        marginTop: '1rem',
    },
    inputField: {
        height: '.5rem',
    },
}));

export default function AddressFilter({ value, placeholder, onChange }) {
    const myClasses = useStyles();

    const [options, setOptions] = useState([]);

    function onInputChange(evt, value) {
        const url =
            'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?text=' +
            value +
            '&countryCode=USA&region=TN&category=Address,Postal,Populated Place&outfields=*&isCollection=false&f=json&maxSuggestions=15';

        fetch(url)
            .then((resp) => resp.json())
            .then((results) => {
                const filteredSuggestions = results.suggestions.filter((suggestion) => suggestion.text.indexOf('TN') > -1);
                setOptions(filteredSuggestions);
            });
    }

    function handleChange(evt, selection) {
        if (!selection) {
            setOptions([]);
            onChange(null);
        } else {
            fetch(
                'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?SingleLine=' +
                    selection.text +
                    '&magicKey=' +
                    selection.magicKey +
                    '&f=json'
            )
                .then((resp) => resp.json())
                .then((result) => {
                    if (result.candidates.length > 0) {
                        onChange(result.candidates[0]);
                    }
                });
        }
    }

    return (
        <div className={myClasses.root}>
            <Autocomplete
                value={value}
                options={options}
                onInputChange={onInputChange}
                getOptionLabel={(option) => (!!option ? option.text || option.address || '' : '')}
                onChange={handleChange}
                filterSelectedOptions
                freeSolo
                renderInput={(params) => {
                    const { InputProps, ...otherParams } = params;

                    return (
                        <TextField
                            placeholder={placeholder}
                            InputProps={{
                                ...InputProps,
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <LocationOnIcon style={{ marginLeft: '.5rem', color: '#e7e3db' }} />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: myClasses.inputField,
                                },
                            }}
                            {...otherParams}
                        />
                    );
                }}
            />
        </div>
    );
}
