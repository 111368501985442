import { makeStyles } from '@mui/styles';

import { Button, Typography } from '@mui/material';

import CardFilterMultiselect from './CardFilterMultiselect';

import { useSrclists } from '../DataProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.muted.main,
        padding: '1rem',
        marginRight: '.5rem',
        borderRadius: '.25rem',
    },
    toggleItem: {
        color: theme.palette.primary,

        border: '1px solid ' + theme.palette.text.primary,
        fontSize: 21,
        padding: 5,
        textTransform: 'none',
        '&$selected': {
            color: '#ffffff',
            backgroundColor: theme.palette.text.primary,
        },
    },
    selected: {
        color: '#ffffff',
        border: 0,
        fontSize: 21,
        fontWeight: 'bold',
        backgroundColor: '#000000',
    },
    centered: {
        margin: 'auto',
    },
    hr: {
        borderTopWidth: '1.5px',
        borderColor: '#000066',
    },
    filterGrid: {
        padding: 0,
        marginLeft: 5,
    },
    topFilterGrid: {
        padding: 0,
        marginLeft: 8,
        marginRight: '1rem',
    },
    filterLong: {
        padding: 0,
        minWidth: '100px',
    },
    schoolBadge: {
        marginTop: '10px',
        paddingLeft: 15,
    },
    schoolBadges: {
        paddingLeft: 15,
        fontSize: '1.5em',
        verticalAlign: 'middle',
    },
    clearButton: {
        backgroundColor: theme.palette.tertiary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.tertiary.dark,
        },
    },
    fieldset: {
        marginBottom: '1rem',
        borderRadius: '.25rem',
        backgroundColor: 'white',
        padding: '.4rem',
    },
    infoIcon: {
        color: theme.palette.muted.main,
        '&:hover': {
            color: theme.palette.muted.dark,
        },
        position: 'absolute',
        right: '-.5rem',
        bottom: '-.5rem',
    },
}));

function CardsFilters({ viewType, filters, onFilterChange }) {
    const classes = useStyles();

    const srclists = useSrclists();

    function handleFilterChange(filter) {
        let newFilters = filter || {};

        if (!!filter) {
            newFilters = Object.assign({}, filters);

            if (filter.selection === true) {
                newFilters[filter.filterName] = filter.selection;
            } else if (!filter.selection.length) {
                delete newFilters[filter.filterName];
            } else {
                newFilters[filter.filterName] = filter.selection;
            }
        }

        onFilterChange(newFilters);
    }

    return (
        <div className={classes.root}>
            <Typography style={{ marginBottom: '1rem' }}>
                <b>Filter Your Search</b>
            </Typography>
            {viewType === 'schools' ? (
                <div className={classes.topFilterGrid}>
                    <CardFilterMultiselect
                        key={'grades_' + filters.grades?.length}
                        suggestions={srclists.grades}
                        label={srclists.filters.grades.title}
                        placeholder={srclists.filters.grades.placeholder}
                        filterName='grades'
                        value={filters.grades || []}
                        onChange={handleFilterChange}
                        style={{ marginBottom: '1rem' }}
                    />
                    <CardFilterMultiselect
                        key={'districts_' + filters.districts?.length}
                        suggestions={srclists.districts}
                        label={srclists.filters.districts.title}
                        placeholder={srclists.filters.districts.placeholder}
                        filterName='districts'
                        value={filters.districts || []}
                        onChange={handleFilterChange}
                        style={{ marginBottom: '1rem' }}
                    />
                    {
                        <CardFilterMultiselect
                            key={'designation_school_' + filters.designation_school?.length}
                            suggestions={srclists.designation_school}
                            label={srclists.filters.designation_school.title}
                            placeholder={srclists.filters.designation_school.placeholder}
                            filterName='designation_school'
                            value={filters.designation_school || []}
                            labelMinWidth={'125px'}
                            onChange={handleFilterChange}
                            style={{ marginBottom: '1rem' }}
                        />
                    }
                    <CardFilterMultiselect
                        key={'distinctions_school_' + filters.distinctions_school?.length}
                        suggestions={srclists.distinctions_school}
                        label={srclists.filters.distinctions_school.title}
                        placeholder={srclists.filters.distinctions_school.placeholder}
                        filterName='distinctions_school'
                        value={filters.distinctions_school || []}
                        labelMinWidth={'125px'}
                        onChange={handleFilterChange}
                        style={{ marginBottom: '1rem' }}
                    />
                    <CardFilterMultiselect
                        key={'school_types_' + filters.school_types?.length}
                        suggestions={srclists.school_types}
                        label={srclists.filters.school_types.title}
                        placeholder={srclists.filters.school_types.placeholder}
                        filterName='school_types'
                        value={filters.school_types || []}
                        labelMinWidth={'125px'}
                        onChange={handleFilterChange}
                        style={{ marginBottom: '1rem' }}
                    />
                </div>
            ) : (
                <div className={classes.topFilterGrid} style={{ maxWidth: '30rem' }}>
                    {
                        <CardFilterMultiselect
                            key={'designation_district_' + filters.designation_district?.length}
                            suggestions={srclists.designation_district}
                            label={srclists.filters.designation_district.title}
                            placeholder={srclists.filters.designation_district.placeholder}
                            filterName='designation_district'
                            value={filters.designation_district || []}
                            onChange={handleFilterChange}
                            style={{ marginBottom: '1rem' }}
                        />
                    }
                    <CardFilterMultiselect
                        key={'distinctions_district_' + filters.distinctions_district?.length}
                        suggestions={srclists.distinctions_district}
                        label={srclists.filters.distinctions_district.title}
                        placeholder={srclists.filters.distinctions_district.placeholder}
                        filterName='distinctions_district'
                        value={filters.distinctions_district || []}
                        onChange={handleFilterChange}
                        style={{ marginBottom: '1rem' }}
                    />
                    <CardFilterMultiselect
                        key={'regions_' + filters.regions?.length}
                        suggestions={srclists.regions}
                        label={srclists.filters.regions.title}
                        placeholder={srclists.filters.regions.placeholder}
                        filterName='regions'
                        value={filters.regions || []}
                        onChange={handleFilterChange}
                        style={{ marginBottom: '1rem' }}
                    />
                </div>
            )}

            <Button
                onClick={() => {
                    handleFilterChange();
                }}
                className={classes.clearButton}
            >
                Clear All Selections
            </Button>
        </div>
    );
}
export default CardsFilters;
