import { useEffect, useState } from 'react';

import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';

import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';

import MenuIcon from '@mui/icons-material/Menu';

import EntityFilter from './EntityFilter';
import StyledTooltip from './StyledTooltip';

import { useSecure, useUser } from '../SecurityProvider';

function UserAvatar({ user }) {
    return (
        <StyledTooltip title={`Logout ${user.userName}`}>
            <Avatar sx={{ backgroundColor: 'background.secondary' }}>{`${user.userName[0]}`}</Avatar>
        </StyledTooltip>
    );
}

const useStyles = makeStyles((theme) => ({
    header: {
        height: '4rem',
        backgroundColor: theme.palette.background.default,
        width: '100%',
        marginBottom: '1rem',
    },
    logo: { marginRight: '1rem' },
    barCenter: {
        width: '75%',
        flexFlow: 'row',
    },
    barBtn: {
        textAlign: 'center',

        '& button': {
            height: '100%',
        },
    },
    barRight: {
        display: 'inherit',
        marginLeft: 'auto',
    },
}));

function Header() {
    const myClasses = useStyles();
    const theme = useTheme();
    const routeNav = useNavigate();
    const isSecure = useSecure();
    const user = useUser();

    const [menuAnchor, setMenuAnchor] = useState(null);

    useEffect(() => {
        window.googleTranslateElementInit = function () {
            new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
        };

        const addScript = document.createElement('script');
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        document.body.appendChild(addScript);
    }, []);

    function handleSelectEntity(entityObj) {
        routeNav(entityObj.filterName + '/' + entityObj.entity.id);
    }

    const { pathname } = useLocation();
    const showSearch = !matchPath('/', pathname) && (!matchPath('/:viewType', pathname) || matchPath('/state', pathname));

    return (
        <AppBar id='header' position='static' className={myClasses.header}>
            <Toolbar>
                <Button
                    onClick={() => {
                        routeNav('/');
                    }}
                    style={{ padding: 0, marginTop: '-.5rem' }}
                >
                    <img
                        className={myClasses.logo}
                        alt='Tennessee Department of Education'
                        title='Tennessee Department of Education'
                        src='./images/tn-dept-of-education.png'
                        width='100px'
                    />
                </Button>

                <Grid container className={myClasses.barCenter}>
                    {useMediaQuery(theme.breakpoints.between('xs', !!showSearch ? 'lg' : 'md')) ? (
                        <>
                            <Grid item xs={12} className={myClasses.barBtn}>
                                <IconButton
                                    onClick={(evt) => {
                                        setMenuAnchor(evt.currentTarget);
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={menuAnchor}
                                    keepMounted
                                    open={!!menuAnchor}
                                    onClose={() => {
                                        setMenuAnchor(null);
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            routeNav('schools');
                                            setMenuAnchor(null);
                                        }}
                                    >
                                        School Finder
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            routeNav('districts');
                                            setMenuAnchor(null);
                                        }}
                                    >
                                        District Finder
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            routeNav('state');
                                            setMenuAnchor(null);
                                        }}
                                    >
                                        State Overview
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            window.open('https://www.tn.gov/education/families/report-card.html', 'blank');
                                            setMenuAnchor(null);
                                        }}
                                    >
                                        Resources
                                    </MenuItem>
                                    {isSecure ? (
                                        <>
                                            <MenuItem
                                                onClick={() => {
                                                    window.location.href = '/logout';
                                                }}
                                            >
                                                <UserAvatar user={user} />
                                            </MenuItem>
                                        </>
                                    ) : null}
                                </Menu>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={isSecure ? 2 : 3} className={myClasses.barBtn}>
                                <Button
                                    onClick={() => {
                                        routeNav('/schools');
                                    }}
                                >
                                    School Finder
                                </Button>
                            </Grid>
                            <Grid item xs={isSecure ? 2 : 3} className={myClasses.barBtn}>
                                <Button
                                    onClick={() => {
                                        routeNav('/districts');
                                    }}
                                >
                                    District Finder
                                </Button>
                            </Grid>
                            <Grid item xs={isSecure ? 2 : 3} className={myClasses.barBtn}>
                                <Button
                                    onClick={() => {
                                        routeNav('/state');
                                    }}
                                >
                                    State Overview
                                </Button>
                            </Grid>
                            <Grid item xs={isSecure ? 2 : 3} className={myClasses.barBtn}>
                                <Button
                                    onClick={() => {
                                        window.open('https://www.tn.gov/education/families/report-card.html', 'blank');
                                    }}
                                    className={myClasses.barBtn}
                                >
                                    Resources
                                </Button>
                            </Grid>
                            {isSecure ? (
                                <Grid item xs={2} className={myClasses.barBtn}>
                                    <Button
                                        onClick={() => {
                                            window.location.href = '/logout';
                                        }}
                                    >
                                        <UserAvatar user={user} />
                                    </Button>
                                </Grid>
                            ) : null}
                        </>
                    )}
                </Grid>

                <span className={myClasses.barRight}>
                    {useMediaQuery(theme.breakpoints.up('md')) && !!showSearch ? (
                        <EntityFilter placeholder='Search by Name' onChange={handleSelectEntity} inHeader />
                    ) : null}

                    <div id='google_translate_element'></div>
                </span>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
