import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#174A7C',
            dark: '#1B365D',
        },
        secondary: {
            main: '#2DCCD3',
        },
        tertiary: {
            main: '#E87722',
            dark: '#D85632',
        },
        error: {
            main: '#EE3524',
        },
        text: {
            primary: '#000000',
            secondary: '#AAAAAA',
            tertiary: '#FFFFFF',
        },
        background: {
            default: '#FFFFFF',
            secondary: '#174A7C',
            tertiary: '#EEEEEE',
            quaternary: '#1B365D',
        },
        muted: {
            main: '#E0E0E0',
            dark: '#6E7073',
        },
    },
    typography: {
        fontFamily: "'PermianSlab', 'Open Sans'",
    },
    shape: {
        borderRadius: '.25rem',
    },
    overrides: {
        MuiLink: {
            root: {
                color: 'white',
            },
        },
    },
});

export default theme;
