import { lazy, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuTransition from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DistrictHeader from './DistrictHeader';
import SchoolHeader from './SchoolHeader';
import StateHeader from './StateHeader';

import Suspense from '../components/Suspense';
import TNAssessment from '../components/TNAssessment';
import utils from '../components/utils';

const About = lazy(() => import('./reports/About'));
const AcademicAchievement = lazy(() => import('./reports/AcademicAchievement'));
const Assessment = lazy(() => import('./reports/Assessment'));
const Compare = lazy(() => import('./reports/Compare'));
const ContentAchievement = lazy(() => import('./reports/ContentAchievement'));
const CTEConcentrators = lazy(() => import('./reports/CTEConcentrators'));
const Discipline = lazy(() => import('./reports/Discipline'));
const Dropout = lazy(() => import('./reports/Dropout'));
const ELPA = lazy(() => import('./reports/ELPA'));
const FederalAchievement = lazy(() => import('./reports/FederalAchievement'));
const FederalAchievementRate = lazy(() => import('./reports/FederalAchievementRate'));
const FedGrad = lazy(() => import('./reports/FedGrad'));
const Finance = lazy(() => import('./reports/Finance'));
const Grades = lazy(() => import('./reports/Grades'));
const GraduationRate = lazy(() => import('./reports/GraduationRate'));
const Growth = lazy(() => import('./reports/Growth'));
const ParticipationACT = lazy(() => import('./reports/ParticipationACT'));
const PostsecondaryEnrollment = lazy(() => import('./reports/PostsecondaryEnrollment'));
const Programs = lazy(() => import('./reports/Programs'));
const Ready = lazy(() => import('./reports/Ready'));
const Staff = lazy(() => import('./reports/Staff'));
const TISAAlloc = lazy(() => import('./reports/TISAAlloc'));
const TISACalc = lazy(() => import('./reports/TISACalc'));
const Truancy = lazy(() => import('./reports/Truancy'));

import { useSecure, useUser } from '../SecurityProvider';
import { useEntity } from '../DataProvider';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: theme.palette.primary.dark,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        color: theme.palette.text.tertiary,
        padding: '.5rem',
        marginBottom: '1px',
        borderRadius: '.25rem',
        width: '100%',
        marginLeft: 0,
    },
    headerRight: {
        [theme.breakpoints.up('md')]: {
            borderLeft: 'white 1px solid',
            borderColor: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('md')]: {
            borderTop: 'white 1px solid',
            borderColor: theme.palette.secondary.main,
        },
        paddingLeft: '1rem',
    },

    tabs: {
        backgroundColor: theme.palette.background.default,
        marginBottom: '1rem',
    },
    tab: {
        color: theme.palette.primary.main,
        height: '3rem',
        minHeight: 0,
        minWidth: '7rem',
    },
    iconTabWrapper: {
        flexDirection: 'row-reverse',
    },
    iconTabLabelIcon: {
        paddingTop: '6px',
    },
    submenuItem: {
        paddingLeft: '2rem',
    },
    subSubmenuItem: {
        paddingLeft: '4rem',
    },
}));

const TRANSITION_DURATION = 250;
const STATE_ID = '00000';

export default function Entity() {
    const myClasses = useStyles();
    const routeNav = useNavigate();
    const isSecure = useSecure();
    const user = useUser();

    const { schoolId, districtId, tabId = 'about', menuId = 'about', subPage } = useParams();
    const isSchool = !!schoolId;
    const isDistrict = !!districtId;
    const isState = !isSchool && !isDistrict;

    const entityId = schoolId || districtId || STATE_ID;
    console.info('#Entity with id: ' + entityId);
    const entity = useEntity(entityId);
    console.info('#Entity with entity: ', entity);
    const userDistrict = user?.districtId === '47' || Number(user?.districtId) === Number(entityId);

    const [aboutMenuAnchor, setAboutMenuAnchor] = useState(null);
    const [financeSubmenu, setFinanceSubmenu] = useState(false);

    const [performanceMenuAnchor, setPerformanceMenuAnchor] = useState(null);
    const [stateAchieveSubmenu, setStateAchieveSubmenu] = useState(false);
    const [readyGradSubmenu, setReadyGradSubmenu] = useState(false);
    const [federalSubmenu, setFederalSubmenu] = useState(false);
    const [federalAchieveSubmenu, setFederalAchieveSubmenu] = useState(false);
    const [gradRateSubmenu, setGradRateSubmenu] = useState(false);

    const [schoolsMenuAnchor, setSchoolsMenuAnchor] = useState(null);

    utils.setHomeTitle(!!entity && entity.name);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [tabId, menuId]);

    function onTabChange(evt, theTab) {
        setFinanceSubmenu(false);
        setStateAchieveSubmenu(false);
        setReadyGradSubmenu(false);
        setFederalAchieveSubmenu(false);
        setFederalSubmenu(false);
        setGradRateSubmenu(false);

        if (theTab === 'about') {
            setAboutMenuAnchor(evt.currentTarget);
            setPerformanceMenuAnchor(null);
            setSchoolsMenuAnchor(null);
        } else if (theTab === 'performance') {
            setAboutMenuAnchor(null);
            setPerformanceMenuAnchor(evt.currentTarget);
            setSchoolsMenuAnchor(null);
        } else if (theTab === 'schools') {
            setAboutMenuAnchor(null);
            setPerformanceMenuAnchor(null);
            setSchoolsMenuAnchor(evt.currentTarget);
        } else {
            setAboutMenuAnchor(null);
            setPerformanceMenuAnchor(null);
            setSchoolsMenuAnchor(null);

            routeTo(theTab);
        }
    }
    function onAboutChange(evt) {
        if (tabId === 'about') {
            setAboutMenuAnchor(evt.currentTarget);
            setPerformanceMenuAnchor(null);
            setSchoolsMenuAnchor(null);

            setFinanceSubmenu(false);
        }
    }
    function onPerformanceChange(evt) {
        console.info('#onPerformanceChange with tabId: ' + tabId);
        if (tabId === 'performance') {
            setAboutMenuAnchor(null);
            setPerformanceMenuAnchor(evt.currentTarget);
            setSchoolsMenuAnchor(null);

            setStateAchieveSubmenu(false);
            setReadyGradSubmenu(false);
            setFederalSubmenu(false);
            setFederalAchieveSubmenu(false);
            setGradRateSubmenu(false);
        }
    }
    function onSchoolsChange(evt) {
        if (tabId === 'schools') {
            setAboutMenuAnchor(null);
            setPerformanceMenuAnchor(null);
            setSchoolsMenuAnchor(evt.currentTarget);
        }
    }
    function onMenuChange(theMenu, theTab) {
        setAboutMenuAnchor(null);
        setFinanceSubmenu(false);
        setPerformanceMenuAnchor(null);
        setSchoolsMenuAnchor(null);
        setStateAchieveSubmenu(false);
        setReadyGradSubmenu(false);
        setFederalSubmenu(false);
        setFederalAchieveSubmenu(false);
        setGradRateSubmenu(false);

        routeTo(theTab, theMenu);
    }

    function routeTo(toTab, toMenu, subPage) {
        let path = isSchool ? '/school/' + entity.id : isDistrict ? '/district/' + entity.id : '/state';
        path += '/' + toTab;
        if (!!toMenu) {
            path += '/' + toMenu;
        }
        if (!!subPage) {
            path += '/' + subPage;
        }

        routeNav(path);
    }

    return !!entity ? (
        <>
            {isSchool ? (
                <SchoolHeader school={entity} classes={myClasses} />
            ) : isDistrict ? (
                <DistrictHeader district={entity} classes={myClasses} />
            ) : (
                <StateHeader state={entity} classes={myClasses} />
            )}

            <Paper className={myClasses.tabs}>
                <Tabs value={tabId} onChange={onTabChange} variant='scrollable' allowScrollButtonsMobile>
                    <Tab
                        value='about'
                        label={'About ' + (isSchool ? 'This School' : isDistrict ? 'This District' : 'The State')}
                        icon={!!performanceMenuAnchor ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        iconPosition='end'
                        className={myClasses.tab}
                        onClick={onAboutChange}
                    />
                    <Tab
                        value='performance'
                        label='Performance Metrics'
                        icon={!!performanceMenuAnchor ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        iconPosition='end'
                        classes={{ wrapper: myClasses.iconTabWrapper, labelIcon: myClasses.iconTabLabelIcon }}
                        className={myClasses.tab}
                        onClick={onPerformanceChange}
                    />

                    {!isState ? <Tab value='compare' label={'Compare ' + (isSchool ? 'Schools' : 'Districts')} className={myClasses.tab} /> : null}

                    {/* we show the Assessment tab when unsecured (to show suppressed data)
                          or when secured _AND_ this is the user's district (to show unsuppressed data)
                         we don't show the tab in a secure site _AND_ you're not looking at your own district
                         however, we always show for state.  school has no assessment
                    */}
                    {isState || (isDistrict && (!isSecure || userDistrict)) ? (
                        <Tab value='assessment' label={utils.year(1) + ' TCAP ' + (isState ? 'State' : 'District') + ' Results'} className={myClasses.tab} />
                    ) : null}

                    <Tab
                        value='tcap'
                        label='TCAP Family Portal'
                        onClick={() => {
                            window.open('https://familyreport.tnedu.gov/login', 'blank');
                        }}
                        className={myClasses.tab}
                    />

                    {isDistrict ? (
                        <Tab
                            value='schools'
                            label='School List'
                            icon={!!schoolsMenuAnchor ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            iconPosition='end'
                            classes={{ wrapper: myClasses.iconTabWrapper, labelIcon: myClasses.iconTabLabelIcon }}
                            className={myClasses.tab}
                            style={{ marginLeft: 'auto' }}
                            onClick={onSchoolsChange}
                        />
                    ) : null}
                </Tabs>
            </Paper>

            <Menu
                anchorEl={aboutMenuAnchor}
                open={!!aboutMenuAnchor}
                keepMounted={true}
                onClose={() => {
                    setFinanceSubmenu(false);
                    setAboutMenuAnchor(null);
                }}
                transitionDuration={TRANSITION_DURATION}
                TransitionComponent={MenuTransition}
            >
                <MenuItem
                    onClick={() => {
                        onMenuChange('about', 'about');
                    }}
                >
                    {'About ' + (isSchool ? 'This School' : isDistrict ? 'This District' : 'The State')}
                </MenuItem>

                {/* !isState ? (
                    <MenuItem
                        onClick={() => {
                            onMenuChange('programs', 'about');
                        }}
                    >
                        Programs and Extracurricular
                    </MenuItem>
                    ) : null */}

                <MenuItem
                    onClick={() => {
                        onMenuChange('staff', 'about');
                    }}
                >
                    Staff
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setFinanceSubmenu(!financeSubmenu);
                    }}
                >
                    Finance
                    {financeSubmenu ? <ExpandLessIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />}
                </MenuItem>

                {financeSubmenu && [
                    <MenuItem
                        key='entity_about_finance_bep'
                        onClick={() => {
                            onMenuChange('finance', 'about');
                        }}
                        className={myClasses.submenuItem}
                    >
                        {utils.yearRange()} BEP Expenditures
                    </MenuItem>,

                    !isSchool ? (
                        <MenuItem
                            key='entity_about_finance_tisaalloc'
                            onClick={() => {
                                onMenuChange('tisaalloc', 'about');
                            }}
                            className={myClasses.submenuItem}
                        >
                            {utils.yearRange(+1)} TISA Allocations
                        </MenuItem>
                    ) : null,

                    isSchool ? (
                        <MenuItem
                            key='entity_about_finance_tisacalc'
                            onClick={() => {
                                onMenuChange('tisacalc', 'about');
                            }}
                            className={myClasses.submenuItem}
                        >
                            {utils.yearRange(+1)} TISA Calculator
                        </MenuItem>
                    ) : null,
                ]}
            </Menu>

            <Menu
                anchorEl={performanceMenuAnchor}
                open={!!performanceMenuAnchor}
                keepMounted={true}
                onClose={() => {
                    setStateAchieveSubmenu(false);
                    setReadyGradSubmenu(false);
                    setFederalSubmenu(false);
                    setFederalAchieveSubmenu(false);
                    setGradRateSubmenu(false);
                    setPerformanceMenuAnchor(null);
                }}
                transitionDuration={TRANSITION_DURATION}
                TransitionComponent={MenuTransition}
            >
                {isSchool ? (
                    <MenuItem
                        key='entity_performance_grades'
                        onClick={() => {
                            onMenuChange('grades', 'performance');
                        }}
                    >
                        School Letter Grades
                    </MenuItem>
                ) : null}

                <MenuItem
                    key='entity_performance_stateAchievement'
                    onClick={() => {
                        setStateAchieveSubmenu(!stateAchieveSubmenu);
                    }}
                >
                    State Achievement Rate
                    {stateAchieveSubmenu ? <ExpandLessIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />}
                </MenuItem>

                {stateAchieveSubmenu && [
                    <MenuItem
                        key='entity_performance_achievement'
                        onClick={() => {
                            onMenuChange('academicAchievement', 'performance');
                        }}
                        className={myClasses.submenuItem}
                    >
                        Overall State Achievement Rate
                    </MenuItem>,
                    <MenuItem
                        key='entity_performance_content'
                        onClick={() => {
                            onMenuChange('contentAchievement', 'performance');
                        }}
                        className={myClasses.submenuItem}
                    >
                        State Achievement Rate by Subject Area
                    </MenuItem>,
                ]}

                {!isState ? (
                    <MenuItem
                        key='entity_performance_growth'
                        onClick={() => {
                            onMenuChange('growth', 'performance');
                        }}
                    >
                        Growth
                    </MenuItem>
                ) : null}

                {entity.high_grade >= 9 ? (
                    <>
                        <MenuItem
                            onClick={() => {
                                setGradRateSubmenu(!gradRateSubmenu);
                            }}
                        >
                            State Graduation Rate
                            {gradRateSubmenu ? <ExpandLessIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />}
                        </MenuItem>
                        {gradRateSubmenu && [
                            <MenuItem
                                key='entity_performance_gradrate_graderate'
                                onClick={() => {
                                    onMenuChange('gradRate', 'performance');
                                }}
                                className={myClasses.submenuItem}
                            >
                                State Graduation Rate
                            </MenuItem>,
                            <MenuItem
                                key='entity_performance_gradrate_dropout'
                                onClick={() => {
                                    onMenuChange('dropout', 'performance');
                                }}
                                className={myClasses.submenuItem}
                            >
                                Dropout Rate
                            </MenuItem>,
                            <MenuItem
                                key='entity_performance_gradrate_postsecondary'
                                onClick={() => {
                                    onMenuChange('postsecondary', 'performance');
                                }}
                                className={myClasses.submenuItem}
                            >
                                Postsecondary
                            </MenuItem>,
                        ]}
                    </>
                ) : null}

                {entity.high_grade >= 9 ? (
                    <>
                        <MenuItem
                            onClick={() => {
                                setReadyGradSubmenu(!readyGradSubmenu);
                            }}
                        >
                            <i>Ready Graduate</i>
                            {readyGradSubmenu ? <ExpandLessIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />}
                        </MenuItem>
                        {readyGradSubmenu && [
                            <MenuItem
                                key='entity_performance_ready_ready'
                                onClick={() => {
                                    onMenuChange('ready', 'performance');
                                }}
                                className={myClasses.submenuItem}
                            >
                                <i>Ready Graduate</i>
                            </MenuItem>,
                            <MenuItem
                                key='entity_performance_ready_act'
                                onClick={() => {
                                    onMenuChange('act', 'performance');
                                }}
                                className={myClasses.submenuItem}
                            >
                                ACT
                            </MenuItem>,
                            <MenuItem
                                key='entity_performance_ready_cte'
                                onClick={() => {
                                    onMenuChange('cte', 'performance');
                                }}
                                className={myClasses.submenuItem}
                            >
                                Career Technical Education (CTE)
                            </MenuItem>,
                        ]}
                    </>
                ) : null}

                <MenuItem
                    onClick={() => {
                        setFederalSubmenu(!federalSubmenu);
                    }}
                >
                    Federal Performance Metrics
                    {federalSubmenu ? <ExpandLessIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />}
                </MenuItem>

                {federalSubmenu && [
                    <MenuItem
                        onClick={() => {
                            setFederalAchieveSubmenu(!federalAchieveSubmenu);
                        }}
                        className={myClasses.submenuItem}
                    >
                        Federal Success Rate
                        {federalAchieveSubmenu ? <ExpandLessIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />}
                    </MenuItem>,

                    federalAchieveSubmenu && [
                        <MenuItem
                            key='entity_performance_achievement'
                            onClick={() => {
                                onMenuChange('federalAchievement', 'performance');
                            }}
                            className={myClasses.subSubmenuItem}
                        >
                            Overall Federal Success Rate
                        </MenuItem>,
                        <MenuItem
                            key='entity_performance_content'
                            onClick={() => {
                                onMenuChange('federalAchievementRate', 'performance');
                            }}
                            className={myClasses.subSubmenuItem}
                        >
                            Federal Success Rate by Subject Area
                        </MenuItem>,
                    ],

                    <MenuItem
                        key='entity_performance_federal_truancy'
                        onClick={() => {
                            onMenuChange('truancy', 'performance');
                        }}
                        className={myClasses.submenuItem}
                    >
                        Chronically Out of School
                    </MenuItem>,
                    <MenuItem
                        key='entity_performance_federal_discipline'
                        onClick={() => {
                            onMenuChange('discipline', 'performance');
                        }}
                        className={myClasses.submenuItem}
                    >
                        Discipline
                    </MenuItem>,
                    <MenuItem
                        key='entity_performance_federal_elpa'
                        onClick={() => {
                            onMenuChange('elpa', 'performance');
                        }}
                        className={myClasses.submenuItem}
                    >
                        English Language Proficiency Assessment (ELPA)
                    </MenuItem>,

                    entity.high_grade >= 9 ? (
                        <MenuItem
                            key='entity_performance_federal_fedgrad'
                            onClick={() => {
                                onMenuChange('fedgrad', 'performance');
                            }}
                            className={myClasses.submenuItem}
                        >
                            Federal Graduation Rate
                        </MenuItem>
                    ) : null,
                ]}
            </Menu>

            {isDistrict ? (
                <Menu
                    anchorEl={schoolsMenuAnchor}
                    open={!!schoolsMenuAnchor}
                    onClose={() => {
                        setSchoolsMenuAnchor(null);
                    }}
                    transitionDuration={TRANSITION_DURATION}
                    TransitionComponent={MenuTransition}
                >
                    {entity.schoolList?.map((_school) => (
                        <MenuItem
                            key={_school.id}
                            onClick={() => {
                                setSchoolsMenuAnchor(null);
                                routeNav('/school/' + _school.id);
                            }}
                        >
                            {_school.name}
                        </MenuItem>
                    ))}
                </Menu>
            ) : null}

            <Paper>
                <Suspense>
                    {tabId === 'about' && menuId === 'about' && <About entity={entity} />}
                    {tabId === 'about' && menuId === 'programs' && <Programs entity={entity} />}
                    {tabId === 'about' && menuId === 'staff' && <Staff entity={entity} />}
                    {tabId === 'about' && menuId === 'finance' && <Finance entity={entity} />}
                    {tabId === 'about' && menuId === 'tisaalloc' && <TISAAlloc entity={entity} routeTo={routeTo} />}
                    {tabId === 'about' && menuId === 'tisacalc' && <TISACalc entity={entity} />}

                    {tabId === 'performance' && menuId === 'grades' && !subPage && <Grades entity={entity} routeTo={routeTo} />}
                    {tabId === 'performance' && menuId === 'ready' && <Ready entity={entity} />}
                    {tabId === 'performance' && menuId === 'act' && <ParticipationACT entity={entity} />}
                    {tabId === 'performance' && menuId === 'cte' && <CTEConcentrators entity={entity} />}
                    {tabId === 'performance' && menuId === 'gradRate' && <GraduationRate entity={entity} />}
                    {tabId === 'performance' && menuId === 'dropout' && <Dropout entity={entity} />}
                    {tabId === 'performance' && menuId === 'postsecondary' && <PostsecondaryEnrollment entity={entity} />}
                    {tabId === 'performance' && menuId === 'academicAchievement' && <AcademicAchievement entity={entity} />}
                    {tabId === 'performance' && menuId === 'contentAchievement' && <ContentAchievement entity={entity} />}
                    {tabId === 'performance' && menuId === 'federalAchievement' && <FederalAchievement entity={entity} />}
                    {tabId === 'performance' && menuId === 'federalAchievementRate' && <FederalAchievementRate entity={entity} />}

                    {tabId === 'performance' && menuId === 'truancy' && <Truancy entity={entity} />}
                    {tabId === 'performance' && menuId === 'discipline' && <Discipline entity={entity} />}
                    {tabId === 'performance' && menuId === 'growth' && <Growth entity={entity} />}
                    {tabId === 'performance' && menuId === 'elpa' && <ELPA entity={entity} />}
                    {tabId === 'performance' && menuId === 'fedgrad' && <FedGrad entity={entity} />}

                    {tabId === 'compare' && <Compare entity={entity} />}

                    {tabId === 'assessment' && <Assessment entity={entity} />}
                </Suspense>
            </Paper>

            <TNAssessment />
        </>
    ) : null;
}
