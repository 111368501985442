import { makeStyles } from '@mui/styles';

import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LocationOn from '@mui/icons-material/LocationOn';

import { useSrclists } from '../DataProvider';
import utils from './utils';

const useStyles = makeStyles((theme) => ({
    pin: {
        color: theme.palette.muted.main,
        '&:hover': {
            color: theme.palette.muted.dark,
        },
    },
}));

export default function ResponsiveTable({ rows, viewType, sortBy, sortDirection, includeDistance, onZoomMap, onChangeSort }) {
    const classes = useStyles();
    const srclists = useSrclists();

    const columns = viewType === 'schools' ? schoolColumns(srclists, onZoomMap, classes, includeDistance) : districtColumns(srclists, onZoomMap, classes);

    return (
        <Paper>
            <TableContainer>
                <Table stickyHeader aria-label='sticky table' size='small'>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        minWidth: column.minWidth,
                                        border: 'none',
                                        borderBottom: '1px grey solid',
                                        fontWeight: 600,
                                    }}
                                >
                                    {!!column.sortable ? (
                                        <TableSortLabel
                                            active={true}
                                            direction={sortBy === column.id ? sortDirection : 'asc'}
                                            onClick={() => {
                                                onChangeSort(column.id);
                                            }}
                                            IconComponent={sortBy === column.id ? ArrowDownwardIcon : ImportExportIcon}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    ) : (
                                        <>{column.label}</>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, idx) => (
                            <TableRow hover role='checkbox' tabIndex={-1} key={'row' + idx}>
                                {columns.map((column) => {
                                    const value = column.format ? row : row[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {!!column.format ? column.format(value) : value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

function schoolColumns(srclists, onZoomMap, classes, includeDistance) {
    const columns = [
        {
            id: 'name',
            label: 'School Name',
            format: (row) => {
                return (
                    <div>
                        <a href={import.meta.env.VITE_BASEPATH + '/school/' + row.id} target='_blank'>
                            {row.name}
                        </a>
                    </div>
                );
            },
            minWidth: 15,
            align: 'left',
            sortable: true,
        },
        {
            id: 'district_name',
            label: 'School District',
            format: (row) => {
                return (
                    <a href={import.meta.env.VITE_BASEPATH + '/district/' + row.district_id} target='_blank'>
                        {row.district_name}
                    </a>
                );
            },
            minWidth: 15,
            align: 'left',
            sortable: true,
        },
        {
            id: 'address',
            label: 'Street Address',
            format: (row) => {
                return (
                    <div>
                        {row.address}
                        {!!row.latitude && !!row.longitude ? (
                            <IconButton
                                onClick={() => {
                                    onZoomMap(row);
                                }}
                                className={classes.pin}
                            >
                                <LocationOn />
                            </IconButton>
                        ) : null}
                    </div>
                );
            },
            minWidth: 15,
            align: 'left',
        },
        /* {
            id: 'designation',
            label: 'Designation',
            format: (row) => {
                const designation = !!row.designation_cd ? srclists.designation_school.find((sd) => sd.value === row.designation_cd) : null;
                return !!designation ? designation.label : '';
            },
            minWidth: 15,
            align: 'left',
        }, */
        {
            id: 'grades_served',
            label: 'Grades Served',
            format: (row) => utils.gradeRange(srclists, row.low_grade_cd, row.high_grade_cd),
            minWidth: 15,
            align: 'left',
        },
        {
            id: 'school_type',
            label: 'Type of School',
            format: (row) => row.entity_type,
            minWidth: 15,
            align: 'left',
        },
        {
            id: 'website',
            label: 'Website',
            format: (row) => {
                return (
                    <a href={row.website} target='_blank'>
                        {row.website}
                    </a>
                );
            },
            minWidth: 15,
            align: 'left',
            sortable: true,
        },
    ];
    if (!!includeDistance) {
        columns.push({
            id: 'distance',
            label: 'Distance',
            format: (row) => row.distance?.toString() + ' miles',
            minWidth: 15,
            align: 'left',
            sortable: true,
        });
    }
    return columns;
}

function districtColumns(srclists, onZoomMap, classes) {
    return [
        {
            id: 'name',
            label: 'School District',
            format: (row) => (
                <a href={import.meta.env.VITE_BASEPATH + '/district/' + row.id} target='_blank'>
                    {row.name}
                </a>
            ),
            minWidth: 15,
            align: 'left',
            sortable: true,
        },
        {
            id: 'county',
            label: 'County',
            minWidth: 15,
            align: 'left',
            sortable: true,
        },
        {
            id: 'region',
            label: 'Region Name',
            format: (row) => <p>{row.region}</p>,
            minWidth: 15,
            align: 'left',
            sortable: true,
        },
        {
            id: 'address',
            label: 'Street Address',
            format: (row) => {
                return (
                    <div>
                        {row.address}
                        {!!row.latitude && !!row.longitude ? (
                            <IconButton
                                onClick={() => {
                                    onZoomMap(row);
                                }}
                                className={classes.pin}
                            >
                                <LocationOn />
                            </IconButton>
                        ) : null}
                    </div>
                );
            },
            minWidth: 15,
            align: 'left',
        },
        /* {
            id: 'designation',
            label: 'Designation',
            format: (row) => {
                const designation = !!row.designation_cd ? srclists.designation_district.find((sd) => sd.value === row.designation_cd) : null;
                return !!designation ? designation.label : '';
            },
            minWidth: 15,
            align: 'left',
        }, */
        {
            id: 'website',
            label: 'Website',
            format: (row) => {
                return (
                    <a href={row.website} target='_blank'>
                        {row.website}
                    </a>
                );
            },
            minWidth: 15,
            align: 'left',
            sortable: true,
        },
    ];
}
