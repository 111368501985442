import { makeStyles } from '@mui/styles';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import LocationOnIcon from '@mui/icons-material/LocationOn';

import utils from './utils';

import { useSrclists } from '../DataProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
            borderColor: theme.palette.primary.main,
            border: '1px solid',
            boxShadow: '2px 2px 2px, -1px -1px -1pxs',
        },
    },
    title: {
        fontWeight: 600,
        lineHeight: 1,
    },

    subtitle: {
        lineHeight: 1,
    },
    details: {
        lineHeight: 1,
    },
    grade: {
        fontSize: '3em',
        color: '#000000',
        fontWeight: 700,
        lineHeight: 1,
        height: '1.1em',
        float: 'right',
    },
    topCard: {
        // minHeight: 275,
    },
    pin: {
        color: theme.palette.muted.main,
        '&:hover': {
            color: theme.palette.muted.dark,
        },
    },
}));

export default function SchoolCard({ school, onZoomMap }) {
    const classes = useStyles();

    const srclists = useSrclists();

    const designation = null; // !!school.designation_cd ? srclists.designation_school.find((sd) => sd.value === school.designation_cd) : null;

    return (
        <Card className={classes.root}>
            <CardContent className={classes.topCard}>
                <a href={import.meta.env.VITE_BASEPATH + '/school/' + school.id} target='_blank'>
                    <Typography className={classes.title} gutterBottom>
                        {school.name}
                    </Typography>
                    {!!school.distance ? 'Distance to school: ' + school.distance + ' miles' : null}
                    <Typography className={classes.grade}>{school.rating === 'Not Rated' ? ' ' : school.rating}</Typography>
                </a>

                <Grid container direction='row'>
                    <Grid item xs={12}>
                        <a href={import.meta.env.VITE_BASEPATH + '/district/' + school.district_id} target='_blank'>
                            <Typography className={classes.subtitle} gutterBottom>
                                School District: {school.district_name}
                            </Typography>
                        </a>
                        <Typography className={classes.details} gutterBottom>
                            {school.address}&nbsp;
                            <IconButton
                                onClick={() => {
                                    onZoomMap(school);
                                }}
                                className={classes.pin}
                            >
                                <LocationOnIcon />
                            </IconButton>
                            <br />
                            Grades Served: {utils.gradeRange(srclists, school.low_grade_cd, school.high_grade_cd)}
                            {!!designation ? <p>Designation: {designation.label}</p> : null}
                            <p>Type of School: {school.entity_type}</p>
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={3}>
                        <Typography className={classes.details} gutterBottom></Typography>
                        {school.rating_cd === 'NR' ? (
                            ' '
                        ) : (
                            <b>
                                2019 Overall Score
                                <br />
                                {school.rating + ' / ' + school.score}
                            </b>
                        )}
                    </Grid> */}
                </Grid>
            </CardContent>
        </Card>
    );
}
