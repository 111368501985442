import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        fontSize: '0.8em',
        marginTop: '1rem',
    },

    socialFooter: {
        backgroundColor: theme.palette.muted.main,
        lineHeight: '3.5rem',
        fontFamily: 'Permian Slab, serif',
        fontSize: '1.25rem',
        paddingLeft: '1rem',

        '& a': {
            margin: '0 .5rem',
            textDecoration: 'none',
            whiteSpace: 'nowrap',

            '& img': {
                width: '16px',
                marginLeft: '.5rem',
            },
        },
    },

    infoFooter: {
        backgroundColor: theme.palette.background.tertiary,
        paddingBottom: '1rem',
        marginBottom: '-1.5rem',
    },
    footnoteSection: {
        backgroundColor: theme.palette.background.tertiary,
        color: '#1B365D',
        margin: '.5rem',

        '& .MuiAccordion-root': {
            backgroundColor: theme.palette.background.tertiary,
        },

        '& div.footnote_title': {
            borderBottom: '.4rem solid #1B365D',
            fontFamily: 'Permian Slab, serif',
            fontSize: '1.25rem',
            margin: '0 1rem 0 0',
        },

        '& ul': {
            margin: '0 1rem 0 0',
        },

        '& li': {
            borderBottom: '1px solid #D1D3D4',
            paddingLeft: 0,

            '& a': {
                color: '#131E29',
                textDecoration: 'none',

                '&:hover': {
                    color: '#CC0000',
                },
            },

            '&:last-child': {
                borderBottom: 0,
            },
        },
    },

    subFooter: {
        backgroundColor: '#1B365D',
        lineHeight: '3rem',
        textAlign: 'center',
        padding: '1rem',

        '& a': {
            color: '#FFFFFF',
            margin: '0 .5rem',
            textDecoration: 'none',
            whiteSpace: 'nowrap',

            '&:hover': {
                color: '#CC8888',
            },
        },
    },
}));

function Footer() {
    const classes = useStyles();
    const theme = useTheme();

    function FootnoteSection({ title, items }) {
        return (
            <div className={classes.footnoteSection}>
                {useMediaQuery(theme.breakpoints.up('md')) ? (
                    <>
                        <div className='footnote_title'>{title}</div>

                        <List>
                            {items.map((item, idx) => (
                                <ListItem key={idx}>
                                    <Link href={item.href} target='_blank' style={{ cursor: 'pointer' }}>
                                        {item.text}
                                    </Link>
                                </ListItem>
                            ))}
                        </List>
                    </>
                ) : (
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} className='footnote_title'>
                            {title}
                        </AccordionSummary>
                        <AccordionDetails>
                            <List>
                                {items.map((item, idx) => (
                                    <ListItem key={idx}>
                                        <Link href={item.href} target='_blank' style={{ cursor: 'pointer' }}>
                                            {item.text}
                                        </Link>
                                    </ListItem>
                                ))}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                )}
            </div>
        );
    }

    function FooterLink({ text, href }) {
        return (
            <Link href={href} target='_blank'>
                {text}
            </Link>
        );
    }

    return (
        <div className={classes.root}>
            <div className={classes.socialFooter}>
                <div style={{ maxWidth: '1200px', margin: 'auto' }}>
                    Social Media
                    <FooterLink href='https://www.facebook.com/TennesseeEducation' text={<img src='./images/facebook.png' />} />
                    <FooterLink href='https://twitter.com/TNedu' text={<img src='./images/twitter.png' />} />
                    <FooterLink href='https://www.youtube.com/user/TNDeptofEducation' text={<img src='./images/youtube.png' />} />
                    <FooterLink href='https://www.instagram.com/tndeptofedu' text={<img src='./images/instagram.png' />} />
                </div>
            </div>

            <div className={classes.infoFooter}>
                <Grid style={{ maxWidth: '1200px', margin: 'auto' }} container>
                    <Grid item xs={12} md={8} container>
                        <Grid item xs={12} md={4}>
                            <FootnoteSection
                                title='For Families'
                                items={[
                                    { text: 'Best for All Central', href: 'https://bestforall.tnedu.gov/' },
                                    { text: 'TCAP Family Portal', href: 'https://familyreport.tnedu.gov/login' },
                                    { text: 'About TCAP', href: 'https://www.tn.gov/education/districts/lea-operations/assessment/tnready.html' },
                                ]}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FootnoteSection
                                title='Quick Links'
                                items={[
                                    { text: 'TEAM', href: 'https://www.tn.gov/education/tennessee-educator-redirect.html' },
                                    { text: 'Licensure', href: 'https://www.tn.gov/education/educators/licensing.html' },
                                    { text: 'Teaching Jobs in Tennessee', href: 'http://tennesseeteaching.com/' },
                                    { text: 'TCAP Educator Portal', href: 'https://educatorreport.tnedu.gov/login' },
                                    /* { text: 'Assessment', href: 'https://www.tn.gov/education/assessment.html' },
                                    { text: 'Educator Look-up', href: 'https://tdoe.tncompass.org/public' },
                                    { text: 'School Directory', href: 'https://k-12.education.tn.gov/sde/' }, */
                                ]}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FootnoteSection
                                title='About TDOE'
                                items={[
                                    { text: 'About the Commissioner', href: 'https://www.tn.gov/education/about-the-tdoe/commissioner.html' },
                                    { text: 'Department of Education', href: 'https://www.tn.gov/education.html' },
                                    { text: 'News', href: 'https://www.tn.gov/education/news.html' },
                                    /* { text: 'Career Opportunities', href: 'https://www.tn.gov/education/about-tdoe/career-opportunities.html' }, */
                                    {
                                        text: 'Contact',
                                        href: 'https://www.tn.gov/education/about-the-tdoe/frequently-requested-contact-information.html',
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={0} md={1} />
                    <Grid item xs={12} md={3}>
                        <div style={{ margin: '0 1rem' }}>
                            <p>
                                <b>Department of Education</b>
                            </p>
                            <br />
                            <p>
                                <i>710 James Robertson Parkway</i>
                            </p>
                            <p>
                                <i>Nashville, TN 32743</i>
                            </p>
                            <img src='./images/tn-seal.png' alt='Tennessee Education Agency Logo' height='150px' />
                        </div>
                    </Grid>
                </Grid>
            </div>

            <img src='./images/footer-starball.png' style={{ width: '50px', marginLeft: '50%', marginBottom: '-2rem' }} />

            <div className={classes.subFooter}>
                <Grid style={{ maxWidth: '1200px', margin: 'auto' }} container>
                    <Grid item xs={12} md={6}>
                        <FooterLink href='https://www.tn.gov/state-services.html' text='TN.gov Services' />
                        <FooterLink href='https://www.tn.gov/directory.html' text='TN.gov Directory' />
                        <FooterLink href='https://www.tn.gov/transparenttn.html' text='Transparent TN' />
                        <FooterLink href='https://www.tn.gov/web-policies/privacy-statement.html' text='Web Policies' />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FooterLink href='https://www.tn.gov/about-tn/state-symbols.html' text='About Tennessee' />
                        <FooterLink href='https://www.tn.gov/humanrights/file-a-discrimination-complaint/title-vi1/title-vi-disclaimer.html' text='Title VI' />
                        <FooterLink href='https://www.tn.gov/web-policies/accessibility.html' text='Accessibility' />
                        <FooterLink href='https://www.tn.gov/help.html' text='Help & Contact' />
                        <FooterLink href='https://www.tn.gov/survey.html' text='Survey' />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Footer;
