import { makeStyles } from '@mui/styles';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import LocationOnIcon from '@mui/icons-material/LocationOn';

const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
            borderColor: theme.palette.primary.main,
            border: '1px solid',
            boxShadow: '2px 2px, -1px -1px',
        },
    },
    topCard: {
        // minHeight: 275,
    },

    title: {
        fontWeight: 500,
        lineHeight: 1,
    },
    subtitle: {
        lineHeight: 1,
    },
}));

export default function DistrictCard({ district, onZoomMap }) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent className={classes.topCard}>
                <a href={import.meta.env.VITE_BASEPATH + '/district/' + district.id} target='_blank'>
                    <Typography className={classes.title} gutterBottom>
                        District: {district.name}
                    </Typography>
                </a>
                <Typography className={classes.subtitle} gutterBottom>
                    {district.address}
                    <IconButton
                        onClick={() => {
                            onZoomMap(district);
                        }}
                        className={classes.pin}
                    >
                        <LocationOnIcon />
                    </IconButton>
                    <br />
                    <a href={'http://' + district.website} target='_blank'>
                        {district.website}
                    </a>
                </Typography>
            </CardContent>
        </Card>
    );
}
