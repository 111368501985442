import makeStyles from '@mui/styles/makeStyles';

import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
    tooltip: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
        fontSize: '1rem',
        padding: '.5rem',
        boxShadow: theme.shadows[5],
    },
}));

export default function StyledTooltip({ title, children }) {
    const myClasses = useStyles();

    return (
        <Tooltip title={title} classes={{ tooltip: myClasses.tooltip }}>
            {children}
        </Tooltip>
    );
}
