import { useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import AddressFilter from '../components/AddressFilter';
import EntityFilter from '../components/EntityFilter';
import TNAssessment from '../components/TNAssessment';

const infoStyles = makeStyles((theme) => ({
    informationBox: {
        margin: '2rem auto',
        textAlign: 'center',
        width: '75%',
    },
    informationTitle: {
        fontSize: '1.25rem',
        color: theme.palette.primary.main,
    },
    informationInfo: {
        fontSize: '2rem',
        color: theme.palette.muted.dark,
    },
    informationDesc: {
        fontSize: '1rem',
        color: theme.palette.text.primary,
        '& ul': {
            display: 'inline-block',
            textAlign: 'left',
            listStylePosition: 'inside',
            marginTop: '0',
            marginBottom: '0',
        },
    },
}));
function Information({ title, info, description }) {
    const myClasses = infoStyles();

    return (
        <div className={myClasses.informationBox}>
            <div className={myClasses.informationTitle}>{title}</div>
            <div className={myClasses.informationInfo}>{info}</div>
            <div className={myClasses.informationDesc}>{description}</div>
        </div>
    );
}

function SearchButton({ btnAction, bgImage, text }) {
    const btnProps =
        typeof btnAction === 'string'
            ? {
                  onClick: () => {
                      window.open(btnAction, '_blank');
                  },
              }
            : { onClick: btnAction };

    return (
        <div style={{ textAlign: 'center' }}>
            <Link component='button' {...btnProps}>
                <div
                    style={{
                        margin: 'auto',
                        background: "url('./images/" + bgImage + "') 0 0",
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '35px 35px',
                        height: '35px',
                        width: '35px',
                    }}
                />
                <br />
                <span style={{ marginTop: '8px', fontWeight: '500', color: 'white' }} color='textPrimary'>
                    {text}
                </span>
            </Link>
        </div>
    );
}

const homeStyles = makeStyles((theme) => ({
    hr: {
        backgroundColor: theme.palette.primary.dark,
        height: '.35rem',
    },
    searchSection: {
        backgroundColor: theme.palette.primary.dark,
        backgroundImage: "url('./images/home-header-index.png')",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        color: 'white',
        padding: '2rem',
        borderRadius: '.25rem',
    },
}));
export default function Home() {
    const myClasses = homeStyles();
    const routeNav = useNavigate();
    const theme = useTheme();

    function handleSelectEntity(entityObj) {
        routeNav(entityObj.filterName + '/' + entityObj.entity.id);
    }
    function handleAddressChange(address) {
        routeNav('/schools', { state: address });
    }

    const mediaSMUp = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <div className={myClasses.introRoot}>
            <div className={myClasses.searchSection}>
                <Grid container>
                    {mediaSMUp ? (
                        <Grid item md={6}>
                            <div style={{ fontWeight: 'bold', textAlign: 'left' }}>
                                <p style={{ fontSize: '1.75rem', margin: 0 }}>Tennessee Department of Education</p>
                                <p style={{ fontSize: '3.5rem', margin: 0 }}>Report Card</p>
                            </div>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} md={6} style={{ margin: 'auto' }}>
                        <p>Learn more about your school or district, or find your closest school here.</p>
                        <EntityFilter placeholder='Enter a School or District Name' onChange={handleSelectEntity} />
                        <AddressFilter value='' placeholder='Enter Your Address' onChange={handleAddressChange} />
                    </Grid>
                </Grid>

                {mediaSMUp ? <hr className={myClasses.hr} /> : null}

                <Grid container direction='row' alignItems='center' alignContent='center' spacing={2} style={{ marginTop: '1rem' }}>
                    <Grid item xs={12} sm={4}>
                        <SearchButton
                            btnAction={() => {
                                routeNav('/schools');
                            }}
                            bgImage='icon-browse-school.png'
                            text='Browse Tennessee Schools'
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SearchButton
                            btnAction={() => {
                                routeNav('/districts');
                            }}
                            bgImage='icon-browse-districts.png'
                            text='Browse Tennessee Districts'
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SearchButton
                            btnAction={() => {
                                routeNav('/state');
                            }}
                            bgImage='icon-browse-state.png'
                            text='Browse State Report Card'
                        />
                    </Grid>
                </Grid>
            </div>

            <Information
                title='Increasing awareness and shared accountability'
                info='Clear, accessible information on Tennessee schools'
                description={
                    <span>
                        Families and community members deserve accessible, understandable information about Tennessee schools. Currently, the State Report Card
                        (Report Card) includes a dashboard of detailed, easy-to-understand information about the key indicators that the state uses to
                        understand school and district performance, including achievement, growth, attendance, English learners’ proficiency in English,
                        graduation rate, and postsecondary readiness of students. Information about schools and districts, including student enrollment, contact
                        information, staff information, school and district expenditures and funding, and longitudinal data on student performance over the past
                        four years, is also available on the Report Card. State-level data is also provided as a reference to understand how schools and
                        districts are performing in comparison with others across the state.
                    </span>
                }
            />
            <Information
                title='Accountability and transparency fuel progress'
                info='A tool to better understand how our schools serve all students'
                description={
                    <span>
                        The State Report Card is an important tool for families, educators, communities, and elected officials to understand how our schools and
                        districts are serving all our students. To get a more complete picture, we encourage you to explore the data for each performance
                        indicator, visit schools, talk to educators, parents and families, and students, and review school or district websites. With this
                        knowledge, we can continue to build on Tennessee’s <i>Best for All </i> strategic plan that, together as a state, we will set all
                        students on a path to success.
                    </span>
                }
            />
            <Information title='Additional resources are available in the Resources tab to support your understanding of the data on the State Report Card' />

            <TNAssessment />
        </div>
    );
}
