import { createContext, useContext, useEffect, useState } from 'react';

import Cookies from 'js-cookie';

const SecureContext = createContext();
export function useSecure() {
    return useContext(SecureContext);
}

const UserContext = createContext();
export function useUser() {
    return useContext(UserContext);
}

export default function SecurityProvider({ children }) {
    const [isSecure, setIsSecure] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        console.info('cookies: ', Cookies.get());
        const _secure = !!Cookies.get('tdoe.private');

        if (_secure) {
            fetch('/user')
                .then((resp) => resp.json())
                .then((json) => {
                    setUser(json);
                    setIsSecure(true);
                });
        } else {
            setIsSecure(false);
        }
    }, []);

    return isSecure !== null ? (
        <SecureContext.Provider value={isSecure}>
            <UserContext.Provider value={user}>{children}</UserContext.Provider>
        </SecureContext.Provider>
    ) : null;
}
