import { createContext } from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import { makeStyles } from '@mui/styles';

import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Container from '@mui/material/Container';

import Entities from './pages/Entities';
import Entity from './pages/Entity';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './pages/Home';

import DataProvider from './DataProvider';
import GoogleAnalytics from './GoogleAnalytics';
import MapUtilsProvider from './MapUtilsProvider';
import SecurityProvider from './SecurityProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        height: '100%',
    },
    details: {
        fontSize: '1.1em',
        fontWeight: 500,
        lineHeight: 1,
    },
    appContainer: {
        display: 'flex',
        flex: '1 0 auto',
        flexShrink: 0,
        flexDirection: 'column',
        height: '100%',
    },
    appTop: {
        flexGrow: 1,
        flexShrink: 0,
        maxWidth: '1600px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

const reportStyles = makeStyles((theme) => ({
    container: {
        padding: '0 1rem',
    },

    gridItem: {
        border: '.1px solid ' + theme.palette.background.default,
        borderRadius: '.25rem',
        textAlign: 'center',
    },

    hiddenComponent: {
        display: 'none',
    },

    title: {
        color: theme.palette.text.tertiary,
        backgroundColor: theme.palette.background.quaternary,
        borderSize: '1px',
        borderStyle: 'solid',
        borderColor: theme.palette.muted.main,
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginTop: '.1rem',
        marginLeft: '1rem',
        paddingBottom: '.75rem',
        textAlign: 'center',
    },

    important: {
        fontSize: '2rem',
        color: theme.palette.error.main,
        textAlign: 'center',
    },

    missionStatement: {
        [theme.breakpoints.up('md')]: {
            borderRight: '1px solid',
            paddingRight: '.5rem',
            margin: '1rem 0',
        },
        [theme.breakpoints.down('md')]: {
            borderBottom: '1px solid',
            paddingBottom: '.5rem',
        },
        whiteSpace: 'pre-wrap',
    },

    section: {
        color: theme.palette.text.tertiary,
        backgroundColor: theme.palette.background.secondary,
        fontSize: '1.25rem',
        fontWeight: 'bold',
        marginTop: '1rem',
        marginLeft: '1rem',
        paddingBottom: '.75rem',
    },
    mapBorder: {
        fillOpacity: 0,
        stroke: theme.palette.primary.light,
    },

    toggleMain: {
        borderColor: theme.palette.background.quaternary,
        cursor: 'pointer',
        fontWeight: 'bolder',
        fontSize: '1.2rem',
    },
    toggleSecondary: {
        borderColor: theme.palette.background.quaternary,
        cursor: 'pointer',
    },
}));

export const StylesContext = createContext();

export default function App() {
    const myClasses = useStyles();
    const reportClasses = reportStyles();

    return (
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <SecurityProvider>
                <DataProvider>
                    <MapUtilsProvider>
                        <StylesContext.Provider value={reportClasses}>
                            <Container id='app_container' maxWidth={false} className={myClasses.appContainer} disableGutters={true}>
                                <Container id='app_top' maxWidth={false} direction='column' className={myClasses.appTop}>
                                    <Router>
                                        <GoogleAnalytics />
                                        <Header />

                                        <Routes>
                                            <Route path='/'>
                                                <Route index element={<Home />} />

                                                <Route path='schools' element={<Entities key='schools' viewType='schools' />} />
                                                <Route path='districts' element={<Entities key='districts' viewType='districts' />} />

                                                <Route path='school/:schoolId'>
                                                    <Route index element={<Entity />} />
                                                    <Route path=':tabId' element={<Entity />} />
                                                    <Route path=':tabId/:menuId' element={<Entity />} />
                                                    <Route path=':tabId/:menuId/:subPage' element={<Entity />} />
                                                </Route>

                                                <Route path='district/:districtId'>
                                                    <Route index element={<Entity />} />
                                                    <Route path=':tabId' element={<Entity />} />
                                                    <Route path=':tabId/:menuId' element={<Entity />} />
                                                </Route>

                                                <Route path='state'>
                                                    <Route index element={<Entity />} />
                                                    <Route path=':tabId' element={<Entity />} />
                                                    <Route path=':tabId/:menuId' element={<Entity />} />
                                                </Route>
                                            </Route>
                                            {/* Reroute everything else back to the default screen */}
                                            <Route path='*' element={<Navigate replace to='/' />} />
                                        </Routes>
                                    </Router>
                                </Container>

                                <Footer />
                            </Container>
                        </StylesContext.Provider>
                    </MapUtilsProvider>
                </DataProvider>
            </SecurityProvider>
        </ErrorBoundary>
    );
}
