import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import DistrictCard from './DistrictCard';
import ResponsiveTable from './ResponsiveTable';
import SchoolCard from './SchoolCard';

export default function CardsList({ viewType, entities, includeDistance, onZoomMap, sortBy, sortDirection, onChangeSort }) {
    return (
        <Grid container direction='row' spacing={1} alignItems='stretch'>
            {useMediaQuery(useTheme().breakpoints.down('md')) ? (
                !!entities.length ? (
                    entities.map((entity) => (
                        <Grid item key={'card' + entity.id} xs={12}>
                            {viewType === 'schools' ? (
                                <SchoolCard school={entity} onZoomMap={onZoomMap} />
                            ) : (
                                <DistrictCard district={entity} onZoomMap={onZoomMap} />
                            )}
                        </Grid>
                    ))
                ) : null
            ) : !!entities.length ? (
                <ResponsiveTable
                    key={'table_' + viewType}
                    rows={entities}
                    viewType={viewType}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    includeDistance={!!includeDistance}
                    onZoomMap={onZoomMap}
                    onChangeSort={onChangeSort}
                />
            ) : (
                <Typography>{'No ' + viewType + ' found matching the criteria you selected. Please change your selections.'}</Typography>
            )}
        </Grid>
    );
}
