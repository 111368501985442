import { useEffect } from 'react';

/**
 * @param {scriptUrl} url to source JS script from
 * @param {block} conditional to avoid sourcing the script (since conditional logic cannot wrap this hook)
 */
const SCRIPT_REGISTRY = {};
export function useImportScript(scriptUrl, block) {
    useEffect(() => {
        if (!block) {
            if (!SCRIPT_REGISTRY[scriptUrl]) {
                SCRIPT_REGISTRY[scriptUrl] = true;

                console.info('importing script url: ' + scriptUrl);

                const script = document.createElement('script');
                script.src = scriptUrl;

                document.body.appendChild(script);
            }
        }
    }, [scriptUrl, block]);
}

/**
 *
 * @param {cssUrl} url to source CSS from
 * @param {ntegrity} optional integrity check value
 */
export function useImportCSS(cssUrl, integrity) {
    useEffect(() => {
        console.info('importing css url: ' + cssUrl);

        const linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'stylesheet');
        linkElement.setAttribute('type', 'text/css');
        linkElement.setAttribute('href', cssUrl);
        if (!!integrity) linkElement.setAttribute('integrity', integrity);
        linkElement.setAttribute('crossorigin', '');

        document.head.appendChild(linkElement);
    }, [cssUrl, integrity]);
}
