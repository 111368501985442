import { useState } from 'react';

import { makeStyles } from '@mui/styles';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

/**   Card filter with autosuggest
 * @param placeholder - placeholder text to prompt input
 * @param filterName - string to identify the filter being processed.  This can be dynamic.
 * @param onChange - function process any change.
 *    It will be passed a hash with "selection" being the selected
 *    item and filterName equal to the input "filterName"
 * @param suggestions - JSON array with items to select. There should be properties for "label", "value" and optionally "address"
 * @param showAddress - Option to append "-" plus the value of the address when rendering the item as a suggestion.
 *
 */

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        padding: '.5rem',
        borderRadius: '.25rem',
    },
}));

export default function CardFilterMultiselect({ placeholder, label, value, suggestions, filterName, style, onChange }) {
    const myClasses = useStyles();
    const [myValue, setMyValue] = useState(Object.assign([], value));

    function handleChange(event, newValue) {
        setMyValue(newValue);
        onChange({ selection: newValue, filterName: filterName });
    }

    return (
        <div className={myClasses.root} style={style}>
            <Autocomplete
                multiple
                options={suggestions}
                disableClearable
                getOptionLabel={(option) => option.label}
                onChange={handleChange}
                value={myValue}
                filterSelectedOptions
                renderInput={(params) => <TextField {...params} variant='outlined' label={label} placeholder={placeholder} />}
            />
        </div>
    );
}
