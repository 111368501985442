import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import utils from '../components/utils';

import { useEntity, useSrclists } from '../DataProvider';

export default function SchoolHeader({ school, classes }) {
    const routeNav = useNavigate();

    const srclists = useSrclists();
    const district = useEntity(school?.district_id);

    return !!school && !!district ? (
        <Grid container spacing={2} className={classes.header} style={{ backgroundImage: "url('./images/home-header-school.png')" }}>
            <Grid item xs={12} md={7} style={{ fontSize: '.9rem' }}>
                <h1 style={{ textTransform: 'uppercase', textAlign: 'center' }}>{school.name}</h1>

                <Grid item container>
                    <Grid item xs={3.5}>
                        District:
                        <br />
                        <b>
                            <Link
                                onClick={() => {
                                    routeNav('/district/' + school.district_id);
                                }}
                                style={{ color: 'white', cursor: 'pointer', textDecoration: 'underline' }}
                            >
                                {district.name}
                            </Link>
                        </b>
                    </Grid>

                    <Grid item xs={4.5}>
                        District Website:
                        <br />
                        <b>
                            <a href={utils.ensureProtocol(district.website)} target='_blank' style={{ color: 'white' }}>
                                {utils.formatURL(district.website)}
                            </a>
                        </b>
                    </Grid>

                    <Grid item xs={4}>
                        Grades Served:
                        <br />
                        <b>{utils.gradeRange(srclists, school.low_grade_cd, school.high_grade_cd)}</b>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} className={classes.headerRight}>
                <p>
                    <b>SCHOOL WEBSITE:</b>
                    <br />
                    <a href={utils.ensureProtocol(school.website)} target='_blank' style={{ color: 'white' }}>
                        {utils.formatURL(school.website)}
                    </a>
                </p>

                <p>
                    <b>ADDRESS:</b>
                    <br />
                    {school.address}
                </p>

                <p>
                    <b>PHONE:</b>
                    <br />
                    {utils.formatPhone(school.phone)}
                </p>
            </Grid>
        </Grid>
    ) : null;
}
