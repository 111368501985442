import { createRoot } from 'react-dom/client';

import { StyledEngineProvider } from '@mui/material/styles';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import CssBaseline from '@mui/material/CssBaseline';

import App from './App';
import theme from './theme';

createRoot(document.getElementById('root')).render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </ThemeProvider>
    </StyledEngineProvider>
);
