import { useState } from 'react';

import { makeStyles } from '@mui/styles';

import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

import EntityOption from './EntityOption';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
    },
    inputField: {
        height: '.5rem',
    },
    inputFieldHeader: {
        height: '.5rem',
        width: '28rem !important',
        paddingLeft: '1.5rem !important',
    },
    icon: {
        marginLeft: '.5rem',
        color: theme.palette.muted.main,
    },
    iconHeader: {
        color: theme.palette.muted.main,
        marginTop: '1.75rem',
    },
}));

import { useEntitiesByName } from '../DataProvider';

export default function EntityFilter({ placeholder, onChange, inHeader, onlySchools, onlyDistricts, disabled }) {
    const myClasses = useStyles();

    const inputClass = !!inHeader ? myClasses.inputFieldHeader : myClasses.inputField;
    const iconClass = !!inHeader ? myClasses.iconHeader : myClasses.icon;

    const [name, setName] = useState('');

    const entities = useEntitiesByName(name, onlySchools, onlyDistricts);

    function handleChange(evt, entity) {
        if (!!entity && entity.entity_cd) {
            const filterName = entity.entity_cd === 'S' ? 'school' : 'district';
            onChange({ entity, filterName: filterName });
        }
    }

    return (
        <div className={myClasses.root} style={{ marginTop: !inHeader ? '1rem' : '', marginRight: !!inHeader ? '1rem' : '' }}>
            <Autocomplete
                options={entities}
                disabled={disabled}
                onInputChange={(evt, value) => {
                    setName(value.trim());
                }}
                getOptionLabel={(option) => (!!option && !!option.name ? option.name : '')}
                onChange={handleChange}
                filterSelectedOptions
                freeSolo
                renderInput={(params) => {
                    const { InputProps, ...otherParams } = params;

                    return (
                        <TextField
                            placeholder={placeholder}
                            InputProps={{
                                ...InputProps,
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon className={iconClass} />
                                    </InputAdornment>
                                ),
                                classes: {
                                    input: inputClass,
                                },
                            }}
                            {...otherParams}
                        />
                    );
                }}
                renderOption={(props, option) => <EntityOption key={option.id} entity={option} {...props} />}
            />
        </div>
    );
}
