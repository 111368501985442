import Grid from '@mui/material/Grid';

import utils from '../components/utils';

export default function DistrictHeader({ district, classes }) {
    return !!district ? (
        <Grid container spacing={2} className={classes.header} style={{ backgroundImage: "url('./images/home-header-district.png')" }}>
            <Grid item xs={12} md={7} style={{ fontSize: '.9rem' }}>
                <h1 style={{ textTransform: 'uppercase', textAlign: 'center' }}>{district.name}</h1>

                <Grid item container>
                    <Grid item xs={6}>
                        Total Student Enrollment: <b>{district.enrollment.toLocaleString()}</b>
                    </Grid>

                    <Grid item xs={6}>
                        Schools: <b>{district.schools}</b>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} className={classes.headerRight}>
                <p>
                    <b>WEBSITE:</b>
                    <br />
                    <a href={utils.ensureProtocol(district.website)} target='_blank' style={{ color: 'white' }}>
                        {utils.formatURL(district.website)}
                    </a>
                </p>

                <p>
                    <b>ADDRESS:</b>
                    <br />
                    {district.address}
                </p>

                <p>
                    <b>PHONE:</b>
                    <br />
                    {utils.formatPhone(district.phone)}
                </p>
            </Grid>
        </Grid>
    ) : null;
}
