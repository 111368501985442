import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Grid';

export default function TNAssessment() {
    const theme = useTheme();

    return (
        <Grid container spacing={2} style={{ margin: '2rem 0 .5rem 0' }}>
            <Grid item xs={12} md={3} lg={2}>
                <a href='https://familyreport.tnedu.gov/login' target='_blank'>
                    <img src='./images/tn-dept-of-education.png' width='100px' />
                </a>
                {useMediaQuery(theme.breakpoints.up('md')) ? <span style={{ borderLeft: '1px solid #000', margin: '1rem', height: '20px' }} /> : null}
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
                <p>
                    To see data and TCAP score results for your student,&nbsp;
                    <a href='https://familyreport.tnedu.gov/login' target='_blank'>
                        log in
                    </a>
                    &nbsp;to the TCAP Family Portal to view custom reports and resources for your student.
                </p>
                <p>
                    For downloadable data, please{' '}
                    <a href='https://www.tn.gov/education/districts/federal-programs-and-oversight/data/data-downloads.html'>click here</a>.
                </p>
            </Grid>
        </Grid>
    );
}
