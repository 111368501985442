import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useImportScript } from './resourceUtil';

export default function GoogleAnalytics() {
    useImportScript('https://www.googletagmanager.com/gtag/js?id=G-LM9PV6GKXH', !import.meta.env.VITE_GA_CONFIG);

    useEffect(() => {
        console.info('Google Analytics enabled?: ' + !!import.meta.env.VITE_GA_CONFIG);

        if (!!import.meta.env.VITE_GA_CONFIG) {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', import.meta.env.VITE_GA_CONFIG);
        }
    }, []);

    const location = useLocation();
    useEffect(() => {
        if (!!import.meta.env.VITE_GA_CONFIG) {
            const dl = {
                event: 'pageview',
                pagePath: location.pathname,
            };

            console.info('Google Analytics tracking: ', dl);
            window.dataLayer.push(dl);
        }
    }, [location.pathname]);
}
