import { makeStyles } from '@mui/styles';

import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles((theme) => ({
    option: {
        padding: '.5rem',
        backgroundColor: theme.palette.background.tertiary,
        margin: '.25rem',
        '&:hover': {
            cursor: 'pointer',
            fontWeight: 'bold',
            borderColor: theme.palette.secondary.main,
            borderStyle: 'outset',
        },
    },
    entityType: {
        color: theme.palette.primary.main,
    },
}));

export default function EntityOption({ entity, ...otherProps }) {
    const myClasses = useStyles();

    return (
        <MenuItem {...otherProps} className={myClasses.option}>
            <div>
                <div>{entity.name}</div>
                <div className={myClasses.entityType}>{entity.entity}</div>
                <div>{entity.location}</div>
            </div>
        </MenuItem>
    );
}
